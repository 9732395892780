import { types } from '../reducers/cartReducer';
import { request } from '../helpers/request';
import { addedToast, formatResult, removedToast } from '../helpers';



// Actions

export const startAddToCart = (id, quantity, navigate) => {
   return async dispatch => {
      const localCart = localStorage.getItem('cart');

      const currentCart = JSON.parse(localCart) || [];

      const exists = currentCart.find(p => p.id === id);

      let newCart = [];

      if (!exists) {
         newCart = [...currentCart, { id, quantity }];
      } else {
         newCart = currentCart.map(p => {
            if (p.id === id) {
               return {
                  ...p,
                  quantity: p.quantity + quantity
               }
            }

            return p;
         });
      }

      localStorage.setItem('cart', JSON.stringify(newCart));

      dispatch(startSetCartData(id, true, quantity, navigate));
   }
}

export const setCartData = (cart) => ({
   type: types.SET_CART_DATA,
   payload: cart
});

export const startSetCartData = (id = null, add = false, quantity = null, navigate = () => null) => {
   return async dispatch => {
      dispatch(setLoading(true));

      try {
         const cart = localStorage.getItem('cart');

         const products = JSON.parse(cart) || [];

         const validated = products.filter(p => p?.id && Number.isInteger(p.id) && p?.quantity && Number.isInteger(p.quantity));

         if (products.length !== validated.length) {
            localStorage.setItem('cart', JSON.stringify(validated));
         }

         const response = await request({
            path: '/producto/mostrar',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               datos: [{
                  'id_productos': validated.map(p => p.id)
               }]
            }
         });

         const { productos } = response.data.datos;

         const data = productos.map(p => formatResult(p))

         const cartData = data.map(p => {

            const quantity = validated.find(c => c.id === p.id).quantity

            return {
               ...p,
               quantity
            }
         });

         if (id !== null && add) {
            const { nombre } = cartData.find(p => p.id === id);

            addedToast(nombre, quantity, navigate);
         }
         
         dispatch(setCartData(cartData));
      } catch (error) {
         console.log(error);
      }
      
      dispatch(setLoading(false));
   }
}

export const setLoading = (state) => ({
   type: types.SET_LOADING,
   payload: state
});

export const updateQuantity = (id, quantity) => ({
   type: types.UPDATE_QUANTITY,
   payload: { id, quantity }
});

export const startUpdateQuantity = (id, quantity) => {
   return (dispatch, getState) => {
      dispatch(updateQuantity(id, quantity));

      const { cart } = getState().cart;

      const updated = cart.map(c => ({ id: c.id, quantity: c.quantity }));

      localStorage.setItem('cart', JSON.stringify(updated));
   }
}

export const removeFromCart = (id) => ({
   type: types.REMOVE_FROM_CART,
   payload: id
});

export const startRemoveFromCart = (id) => {
   return (dispatch, getState) => {
      const { cart } = getState().cart;

      const product = cart.find(p => p.id === id);

      removedToast(product.nombre);
      
      dispatch(removeFromCart(id));

      const { cart: toUpdate } = getState().cart;

      const updated = toUpdate.map(p => ({ id: p.id, quantity: p.quantity }));

      localStorage.setItem('cart', JSON.stringify(updated));
   }
}

export const emptyCart = () => ({
   type: types.EMPTY_CART
});

export const startEmptyCart = () => {
   return dispatch => {
      dispatch(emptyCart());
      localStorage.setItem('cart', JSON.stringify([]));
   
      dispatch(cleanCoupon);
      sessionStorage.removeItem('coupon');
   }
}



// Coupon

export const setDiscount = (coupon, discount, discountPercent, valid) => ({
   type: types.SET_DISCOUNT,
   payload: { coupon, discount, discountPercent, valid }
});

export const startValidateCoupon = (coupon) => {
   return async dispatch => {
      dispatch(setLoadingCoupon(true));

      try {
         const response = await request({
            path: '/codigo-descuento/obtener/codigo',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               datos: [{
                  codigo: [coupon]
               }]
            }
         });

         const { datos, vigencia_cupon } = response.data;

         const { codigo, monto_descuento, porcentaje_descuento } = datos.codigo_descuento;

         sessionStorage.setItem('coupon', codigo);

         if (porcentaje_descuento) {
            dispatch(setDiscount(codigo, Number(porcentaje_descuento), true, vigencia_cupon));
         } else {
            dispatch(setDiscount(codigo, Number(monto_descuento), false, vigencia_cupon));
         }
      } catch (error) {
         console.log(error);

         sessionStorage.removeItem('coupon');
         
         dispatch(setDiscount(null, null, null, false));
      }

      dispatch(setLoadingCoupon(false));
   }
}

export const startGetDiscount = () => {
   return async dispatch => {
      const coupon = sessionStorage.getItem('coupon');

      coupon && dispatch(startValidateCoupon(coupon));
   }
}

export const setLoadingCoupon = (state) => ({
   type: types.SET_LOADING_COUPON,
   payload: state
});

export const cleanCoupon = () => ({
   type: types.CLEAN_COUPON
});