export const types = {
   SET_OFFICES: '[OFFICES] set offices list',
   SET_LOADING_OFFICES: '[OFFICES] set loading offices value',
   SET_ALLIES: '[OFFICES] set allies list',
   SET_LOADING_ALLIES: '[OFFICES] set loading allies value',
   // Map data
   SET_MAP_SRC: '[OFFICES] set map src data'
}



const initialState = {
   offices: [],
   allies: [],
   loadingOffices: true,
   loadingAllies: true,

   map: null,
}



export const officesReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_OFFICES:
         return {
            ...state,
            offices: action.payload
         }

      case types.SET_ALLIES:
         return {
            ...state,
            allies: action.payload
         }

      case types.SET_LOADING_OFFICES:
         return {
            ...state,
            loadingOffices: action.payload
         }

      case types.SET_LOADING_ALLIES:
         return {
            ...state,
            loadingAllies: action.payload
         }

      case types.SET_MAP_SRC:
         return {
            ...state,
            map: action.payload
         }

      default:
         return state;
   }
}