import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom';



// Components
import LoadingLazy from '../components/ui/LoadingLazy';



// Routers
import MainRouter from './MainRouter';
import { SelfManagement } from '../views/SelfManagment';




// Lazy views
const Login = lazy(() => import('../views/auth/Login'));
const PasswordRecovery = lazy(() => import('../views/auth/PasswordRecovery'));
const PasswordReset = lazy(() => import('../views/auth/PasswordReset'));
const Register = lazy(() => import('../views/auth/Register'));



const AuthRouter = () => {
   return (
      <Routes>
         <Route path='/login' element={<LoadingLazy element={<Login />} />} />

         <Route path='/registro' element={<LoadingLazy element={<Register />} />} />

         <Route path='/recuperar-clave' element={<LoadingLazy element={<PasswordRecovery />} />} />

         <Route path='/restaurar-clave' element={<LoadingLazy element={<PasswordReset />} />} />

         <Route path='/autoservicio' element={<LoadingLazy element={<SelfManagement />} />} />

         <Route path='*' element={<MainRouter />} />
      </Routes>
   );
}



export default AuthRouter;