import axios from 'axios';

// API url

export const apiURL = process.env.REACT_APP_API_URL;

// Request function

export const request = async ({
   baseUrl = apiURL,
   location = '/api/v1',
   path,
   method = 'GET',
   headers,
   body,
   asJson = true,
}) => {

   return await axios({
      url: `${baseUrl}${location}${path}`,
      method,
      headers,
      data: asJson ? JSON.stringify(body) : body
   });
}