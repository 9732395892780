import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';



// Actions
import { setSearchBar, toggleMobileMenu } from '../../actions/ui';



// Components
import FontIcon from '../ui/FontIcon';



// Custom hooks
import { useGeoLocation } from '../../hooks/useGeoLocation';



const MobileNav = () => {

   const dispatch = useDispatch();

   const { name } = useSelector(state => state.auth);

   const { mobileMenu } = useSelector(state => state.ui);

   const { commune } = useGeoLocation();

   const handleActiveClass = ({isActive}) => isActive ? 'mobile-nav-element active' : 'mobile-nav-element';

   const handleActiveMenu = () => mobileMenu ? 'mobile-nav-element main-element active' : 'mobile-nav-element main-element';

   const handleMenu = () => {
      dispatch(setSearchBar(false));
      dispatch(toggleMobileMenu());
   }

   const handleNavigation = () => {
      mobileMenu && dispatch(toggleMobileMenu());

      dispatch(setSearchBar(false));
   }

   return (
      <div className='mobile-nav-container'>
         <nav className='mobile-nav'>
            <NavLink
               to={'/'}
               className={handleActiveClass}
               onClick={handleNavigation}
            >
               <FontIcon icon='faHome' />

               <p>Inicio</p>
            </NavLink>

            <NavLink
               to={'/ofertas'}
               className={handleActiveClass}
               onClick={handleNavigation}
            >
               <FontIcon icon='faCommentDollar' />

               <p>Ofertas</p>
            </NavLink>

            <button
               type='button'
               className={handleActiveMenu()}
               onClick={handleMenu}
            >
               <FontIcon icon='faBorderNone' />

               <p>Menú</p>
            </button>

            <NavLink
               to={name ? '/perfil' : '/login'}
               className={handleActiveClass}
               onClick={handleNavigation}
            >
               <FontIcon icon='faCircleUser' />

               <p>Usuario</p>
            </NavLink>

            <div
               className='mobile-nav-element'
            >
               <FontIcon icon='faLocationDot' />

               <p>{commune ? commune : 'No registrada'}</p>
            </div>
         </nav>
      </div>
   );
}



export default MobileNav;