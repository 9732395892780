import { stringCapitalize } from '../helpers/format'

export const types = {
   SET_LOADING: '[CHECKOUT] set loading state',

   // Generación de pedido
   SET_ORDER: '[CHECKOUT] generate order',
}



const initialState = {
   // Pedido exitoso
   generated: {
      orderId: null,
      userId: null,
      userName: null,
      userEmail: null,
      subtotal: null,
      installation: null,
      dispatch: null,
      tax: null,
      discount: null,
      total: null,
      payment: {
         id: null,
         name: null,
         image: null,
      },
      placeTitle: null,
      placeAddress: null,
      productLines: [],
      bill: {
         rut: null,
         address: null,
         social: null,
         giro: null
      },
      tracking: null
   },

   loadingGenerated: false,
}



export const generatedReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_LOADING: {
         const { key, value } = action.payload;

         const keyValue = key.length > 0 ? stringCapitalize(key) : key;

         return {
            ...state,
            [`loading${keyValue}`]: value
         }
      }

      case types.SET_ORDER:
         return {
            ...state,
            generated: {
               ...state.generated,
               orderId: action.payload.id,
               userId: action.payload.userId,
               userName: action.payload.userName,
               userEmail: action.payload.userEmail,
               subtotal: action.payload.subtotal,
               installation: action.payload.installation,
               dispatch: action.payload.dispatch,
               tax: action.payload.tax,
               discount: action.payload.discount,
               total: action.payload.total,
               payment: action.payload.payment,
               placeTitle: action.payload.placeTitle,
               placeAddress: action.payload.placeAddress,
               productLines: action.payload.productLines,
               bill: {
                  rut: action.payload.bill.rut,
                  address: action.payload.bill.address,
                  social: action.payload.bill.social,
                  giro: action.payload.bill.giro
               },
               tracking: action.payload.tracking
            }
         }

      default:
         return state;
   }
}