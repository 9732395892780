import React from 'react';



const LoadingPage = () => {

   return (
      <section className='page-spinner-container'>
         <div className="page-spinner"></div>
      </section>
   );
}



export default LoadingPage;