import { stringCapitalize } from '../helpers/format';

export const types = {
   SET_LOADING: '[MISCS] set loading state',
   
   SET_COUNTRIES: '[MISCS] set countries',
}



const initialState = {
   countries: [],
   loadingCountries: true,
}



export const miscsReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_LOADING: {
         const { key, value } = action.payload;

         const keyValue = key.length > 0 ? stringCapitalize(key) : key;

         return {
            ...state,
            [`loading${keyValue}`]: value
         }
      }

      case types.SET_COUNTRIES:
         return {
            ...state,
            countries: action.payload
         }

      default:
         return state;
   }
}