import { formatLink, request } from '../helpers';
import { types } from '../reducers/uiReducer';



// Actions

export const toggleMobileMenu = () => ({
   type: types.MOBILE_MENU
});

export const setContactNotch = (value = true) => ({
   type: types.CONTACT_NOTCH,
   payload: value
});

export const setSearchBar = (value = true) => ({
   type: types.SEARCH_BAR,
   payload: value
});

export const setWidthSize = (width) => ({
   type: types.SET_WIDTH,
   payload: width
});



// HUD

export const setNavigation = (key, links) => ({
   type: types.SET_NAVIGATION,
   payload: { key, links }
});

export const startGetNavigation = () => {
   return async dispatch => {
      try {
         const response = await request({
            location: '/template/v1',
            path: `/menus?frontend=${process.env.REACT_APP_FRONTEND}`,
            headers: {
               'Content-Type': 'application/json'
            }
         });
         
         const { menuPrincipal = [], menuFooterCol1 = [], menuFooterCol2 = [], menuFooterCol3 = [] } = response.data;

         const nav = menuPrincipal.map(el => formatLink(el, true));

         const col1 = menuFooterCol1.map(el => formatLink(el));
         const col2 = menuFooterCol2.map(el => formatLink(el));
         const col3 = menuFooterCol3.map(el => formatLink(el));

         dispatch(setNavigation('nav', nav));
         dispatch(setNavigation('col1', col1));
         dispatch(setNavigation('col2', col2));
         dispatch(setNavigation('col3', col3));
      } catch (error) {
         console.log(error);
      }
   }
}

export const setGeolocation = ({commune, communeId, region, regionId}) => ({
   type: types.SET_LOCATION,
   payload: { commune, communeId, region, regionId }
});

export const startGetGeolocation = (latitude, longitude) => {
   return async dispatch => {
      try {
         const geoSession = sessionStorage.getItem('geolocation');

         const geoData = JSON.parse(geoSession);

         if (!geoData || !geoData.commune || !geoData.communeId || !geoData.region || !geoData.regionId) {
            sessionStorage.removeItem('geolocation');

            const response = await request({
               path: '/usuario/ubicar/google-maps',
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json'
               },
               body: {
                  datos: {
                     latitud: latitude,
                     longitud: longitude
                  }
               }
            });
            
            const { comuna } = response.data.datos;
   
            const data = {
               commune: comuna.nombre,
               communeId: comuna.id,
               region: comuna.neu_region.nombre,
               regionId: comuna.neu_region.id
            }
      
            dispatch(setGeolocation(data));

            sessionStorage.setItem('geolocation', JSON.stringify(data));
         } else {
            dispatch(setGeolocation(geoData));
         }

         
      } catch (error) {
         console.log(error);
      }
   }
}

export const setMarquee = (mobile, desk, data) => ({
   type: types.SET_MARQUEE,
   payload: { mobile, desk, data }
});

export const startGetMarquee = () => {
   return async dispatch => {
      try {
         const response = await request({
            location: '/template/v1',
            path: `/cinta-informativa?frontend=${process.env.REACT_APP_FRONTEND}`,
            headers: {
               'Content-Type': 'application/json'
            }
         });

         const { data, estatus_movil, estatus_escritorio } = response.data;

         const mappedData = data.map(el => ({
            id: el.id,
            text: el.texto
         }));

         dispatch(setMarquee(estatus_movil, estatus_escritorio, mappedData));
      } catch (error) {
         console.log(error);
      }
   }
}