import { useEffect, useState } from "react"
import { getOrderDetail } from "../../services/SelfManagement/getOrderDetail.service"
import { displayProductCardName } from "../../helpers/products"
import { priceFormat } from "../../helpers"
import moment from "moment"

export const ResultsTableRut = ({ data, handleValue, selectedNV }) => {
  const [details, setDetails] = useState(null)

  useEffect(() => {
    setDetails(null)
  }, [selectedNV])

  const handleFetchDetail = ({ num_pedido, num_nota_venta }) => {
    if (details) return setDetails(null)

    if (num_pedido) {
      getOrderDetail(num_pedido)
        .then((res) => {
          setDetails(res)
        })
      return
    }
  }

  return (
    <article className="self-management-results-table-container">
      <h3 className="self-management-results-table-title">LISTADO DE NOTAS DE VENTA</h3>

      <table className="self-management-results-table">
        <tbody>
          {data?.length
            ? data.map(({
              CLIENTE_NOMBRE,
              EXT_NUM, ORDER_NUM,
              SHIP_NAME_TEXT, SHIP_ADDR_TEXT, agendamiento, nv
            }, i) => (
              <tr key={`${i}-${ORDER_NUM}-${CLIENTE_NOMBRE ?? 'usuario'}`}>
                <td>
                  <div
                    className={`self-management-table-info ${nv?.ORDER_NUM === selectedNV?.nota_venta && i === selectedNV?.index ? 'selected' : ''}`}
                    onClick={() => handleValue({ nota_venta: nv?.ORDER_NUM, index: i })}
                  >
                    <div className="self-management-table-data data">
                      <span>Pedido: <b>{nv?.EXT_NUM ?? 'N/A'}</b></span>
                      <span>Nota Venta: <b>{nv?.ORDER_NUM ?? 'Error'}</b></span>
                      <span>Centro de servicio: <b>{`${nv?.SHIP_NAME_TEXT ?? 'Error'}, ${nv?.SHIP_ADDR_TEXT ?? 'Desconocido'}`}</b></span>
                    </div>

                    <div className="self-management-table-agenda agenda">
                      {agendamiento
                        ? <div className="self-management-detailed-nv-agenda-data">
                          <h4>Agenda</h4>
                          <span>{moment(agendamiento?.fecha).format('DD/MM/YYYY')}</span>
                          <span>{agendamiento?.hora_agenda?.hora_inicio_intervalo ?? 'Error'}</span>
                        </div>

                        : <b className="agenda-title none">Sin agenda</b>
                      }
                    </div>

                    <div className="self-management-table-details details">
                      {nv?.ORDER_NUM === selectedNV?.nota_venta &&
                        i === selectedNV?.index &&
                        <>
                          <button name="details" onClick={() => handleFetchDetail({ num_pedido: nv?.EXT_NUM, num_nota_venta: nv?.ORDER_NUM })}>{!details ? '▼' : '▲'} Ver {!details ? 'más' : 'menos'} detalles</button>

                          {details &&
                            <div className="self-management-table-detailed">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Artículo</th>
                                    <th>Cantidad</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {details?.neu_linea_pedidos?.length &&
                                    details.neu_linea_pedidos.map(p => (
                                      <tr key={`linea-${p.id}`}>
                                        <td>{displayProductCardName(p.neu_producto.nombre)}</td>
                                        <td>{p.cantidad_comprada}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>

                              <div className="self-management-table-detailed footer">
                                <span>Estatus: <b>{details?.neu_estatus_pedido?.nombre ?? 'Error'}</b></span>
                                <span>Total productos: <b>{details?.total_productos ?? 'Error'}</b></span>
                                <span>Total: <b>{priceFormat(details?.monto_total_pedido) ?? 'Error'}</b></span>
                              </div>
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                </td>
              </tr>)
            )

            : <tr><td colSpan={3} className="no-data">No se encontraron resultados para su búsqueda</td></tr>
          }
        </tbody>
      </table>
    </article >
  )
}