import { request } from "../../helpers";

export const listNVByRut = async (data) => {
  try {
    const { rut } = data

    const response = await request({
      path: `/notas-venta?rut=${rut}`
    })

    return response
  } catch (err) {
    // console.error(err);
    return err
  }
}

export const listOneNV = async (data) => {
  try {
    const { pedido, nota_venta } = data
    if (!pedido && !nota_venta) throw new Error('Falta el parámetro de búsqueda')

    const response = await request({
      path: `/notas-venta/${nota_venta || pedido}`,
      method: 'POST',
      // Nota: se debe enviar como asJson: false porque sino tira error
      asJson: false,
      body: {
        campo: nota_venta ? 'nota venta' : 'pedido'
      }
    })

    return response
  } catch (err) {
    // console.error(err);
    return err
  }
}