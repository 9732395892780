export const types = {
   // Obtener listado de marcas existentes
   SET_BRANDS_DATA: '[BRANDS] set brands list',
   // Settear estado de loading
   SET_LOADING_BRANDS: '[BRANDS] set brands loading state',
   // Settear filtro de búsqueda (buscador reactivo)
   SET_FILTER: '[BRANDS] set filter',
   
   // Información de una marca
   SET_BRAND_INFO: '[BRANDS] set brand info',

   // Obtener listado de productos de una marca
   SET_PRODUCTS_BY_BRAND: '[BRANDS] set products by brand',
   // Settear estado de loading
   SET_LOADING_PRODUCTS: '[BRANDS] set products loading state'
}



const initialState = {
   // Sección de marcas
   brandsData: [],
   loadingBrands: true,
   filter: '',
   // Información y productos de una marca
   brand: null,
   products: [],
   total: null,
   pages: null,
   loadingProducts: true
}



export const brandsReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_BRANDS_DATA:
         return {
            ...state,
            brandsData: action.payload
         }

      case types.SET_LOADING_BRANDS:
         return {
            ...state,
            loadingBrands: action.payload
         }

      case types.SET_FILTER:
         return {
            ...state,
            filter: action.payload
         }

      case types.SET_BRAND_INFO:
         return {
            ...state,
            brand: action.payload
         }

      case types.SET_PRODUCTS_BY_BRAND:
         return {
            ...state,
            products: action.payload.products,
            total: action.payload.total,
            pages: action.payload.pages,
         }

      case types.SET_LOADING_PRODUCTS:
         return {
            ...state,
            loadingProducts: action.payload
         }

      default:
         return state;
   }
}