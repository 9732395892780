import React from 'react';
import Icon from '@mdi/react';
import * as path from '@mdi/js';



const MdiIcon = ({icon, ...props}) => {

   return (
      <Icon path={path[icon]} {...props} />
   );
}



MdiIcon.propTypes = {
   icon: (props, propName, componentName) => {
   
      const entries = Object.entries({...path});
   
      const icons = entries.map(([key, _]) => key);
   
      if (!icons.includes(props[propName])) {
   
         const errorIcons = icons.map(icon => `"${icon}"`);
   
         return new Error(
            'Invalid prop `' + propName + '` of value `' + props[propName] + '` supplied to `' + componentName + '`, expected one of [' + errorIcons.toString() + '].'
         );
      }
   }
}



export default MdiIcon;