import { stringCapitalize } from '../helpers/format';

export const types = {
   // Search
   SET_SUGGESTIONS: '[SEARCH] set search suggestions',
   // Premium
   SET_PREMIUM: '[SEARCH] set search premium results',
   // Results
   SET_RESULTS: '[SEARCH] set search results',
   SET_LOADING: '[SEARCH] set loading value',
   // Attributes
   SET_ATTRIBUTES_LOADING: '[SEARCH] set attributes loading value',
   SET_ATTRIBUTE: '[SEARCH] set attributes values',
   // Filters
   SET_FILTERS: '[SEARCH] set filters list',
   SET_FILTERS_LOADING: '[SEARCH] set filters loading value',
   SET_FILTERS_MODAL: '[SEARCH] toggle filters modal',
   SET_ORDERING_MODAL: '[SEARCH] toggle ordering modal'
}




const initialState = {
   // Search
   suggestions: [],
   // Premium
   premium: [],
   loadingPremium: false,
   // Results
   results: [],
   total: null,
   pages: null,
   loading: true,
   // Attributes
   aros: [],
   anchos: [],
   altos: [],
   loadingAttr: true,
   // Filters
   filters: null,
   loadingFilters: true,
   filtersModal: false,
   orderingModal: false
}



export const searchReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_PREMIUM:
         return {
            ...state,
            premium: action.payload,
         }

      case types.SET_RESULTS:
         return {
            ...state,
            results: action.payload.results,
            total: action.payload.total,
            pages: action.payload.pages
         }

      case types.SET_LOADING: {
         const { key, value } = action.payload;

         const keyValue = key.length > 0 ? stringCapitalize(key) : key;

         return {
            ...state,
            [`loading${keyValue}`]: value
         }
      }

      case types.SET_ATTRIBUTE:
         return {
            ...state,
            [action.payload.key]: action.payload.values
         }

      case types.SET_SUGGESTIONS:
         return {
            ...state,
            suggestions: action.payload
         }

      case types.SET_FILTERS:
         return {
            ...state,
            filters: action.payload
         }

      case types.SET_FILTERS_MODAL:
         return {
            ...state,
            filtersModal: !state.filtersModal
         }

      case types.SET_ORDERING_MODAL:
         return {
            ...state,
            orderingModal: !state.orderingModal
         }

      default:
         return state;
   }
}