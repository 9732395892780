import Swal from 'sweetalert2';



export const registerAlert = (email) => {
   Swal.fire({
      title: '¡Registro exitoso!',
      text: `El correo ${email} ha sido registrado exitosamente.`,
      toast: true,
      timer: 6000,
      position: 'top-end',
      width: 300,
      showConfirmButton: false,
      customClass: {
         popup: 'cart-toast-container',
         title: 'cart-toast-title',
         htmlContainer: 'cart-toast-text',
         footer: 'cart-toast-footer',
         actions: 'cart-toast-btns',
         confirmButton: 'cart-toast-btn link',
         cancelButton: 'cart-toast-btn',
      }
   });
}