import React, { useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';



// Actions
import { setSearchBar } from '../../actions/ui';



// Components
import FontIcon from './FontIcon';
import Modal from './Modal';
import SearchSuggestions from './SearchSuggestions';



const SearchBar = () => {

   const navigate = useNavigate();

   const dispatch = useDispatch();

   const { searchBar } = useSelector(state => state.ui);

   const [search, setSearch] = useState('');

   const closeModal = () => dispatch(setSearchBar(false));

   const handleSearch = (e) => {
      e.preventDefault();

      if (search.length > 2) {
         navigate({
            pathname: '/s/',
            replace: true,
            search: createSearchParams({
               search,
            }).toString()
         });

         closeModal();
      }
   }

   return (
      <Modal
         className='search-bar-container'
         state={searchBar}
         close={closeModal}
      >
         <div className='search-bar'>
            <form
               className='search-bar-form'
               onSubmit={handleSearch}
            >
               <input
                  type='text'
                  placeholder='¿Qué estaba buscando?'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
               />

               <button
                  type='submit'
               >
                  <FontIcon icon='faSearch' />
               </button>
            </form>

            <SearchSuggestions search={search} />
         </div>
      </Modal>
   );
}



export default SearchBar;