import Swal from 'sweetalert2';

const nameFilters = ['NEUMATICO', 'NEUMÁTICO']

export const addedToast = (name, quantity, navigate) => {
   Swal.fire({
      title: 'Producto agregado al carro',
      text: name,
      footer: `Cantidad agregada: ${quantity}`,
      toast: true,
      timer: 6000,
      position: 'top-end',
      width: 300,
      showConfirmButton: true,
      confirmButtonText: 'Ir al carro',
      showCancelButton: true,
      cancelButtonText: 'Seguir navegando',
      customClass: {
         popup: 'cart-toast-container',
         title: 'cart-toast-title',
         htmlContainer: 'cart-toast-text',
         footer: 'cart-toast-footer',
         actions: 'cart-toast-btns',
         confirmButton: 'cart-toast-btn link',
         cancelButton: 'cart-toast-btn',
      },
      buttonsStyling: false
   }).then(result => {
      if (result.isConfirmed) {
         navigate('/carrito');
      }
   });
}

export const removedToast = (name) => {
   Swal.fire({
      title: 'Producto eliminado del carro',
      text: name,
      toast: true,
      timer: 3000,
      position: 'top-end',
      width: 300,
      showConfirmButton: false,
      customClass: {
         popup: 'cart-toast-container removed',
         title: 'cart-toast-title',
         htmlContainer: 'cart-toast-text',
      }
   });
}

// FUNCION QUE MUESTRA EL NOMBRE EL PRODUCTO EN LA TIENDA CON LOS CRITERIOS ASIGNADOS
export const displayProductCardName = (name) => {
   return name.split(' ').filter((str) => !nameFilters.includes(str)).join(' ')
}