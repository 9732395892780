export const types = {
   SET_CART_DATA: '[CART] get cart data',
   SET_LOADING: '[CART] set loading state',
   UPDATE_QUANTITY: '[CART] update product quantity by its id',
   REMOVE_FROM_CART: '[CART] remove element from cart by its id',
   EMPTY_CART: '[CART] remove all elements from cart',

   // Coupon
   SET_LOADING_COUPON: '[CART] set coupon loading state',
   SET_DISCOUNT: '[CART] set discount data',
   CLEAN_COUPON: '[CART] clean discount data',
}



const initialState = {
   cart: [],
   loading: true,
   
   // Coupon
   coupon: null,
   discount: null,
   discountPercent: null,
   valid: null,
   loadingCoupon: false,
}



export const cartReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_CART_DATA:
         return {
            ...state,
            cart: action.payload
         }

      case types.SET_LOADING:
         return {
            ...state,
            loading: action.payload
         }

      case types.UPDATE_QUANTITY: {
         const updated = state.cart.map(c => {
            if (c.id === action.payload.id) {
               return { ...c, quantity: c.quantity + action.payload.quantity }
            }
            return c
         });

         return {
            ...state,
            cart: updated
         }
      }

      case types.REMOVE_FROM_CART:
         return {
            ...state,
            cart: state.cart.filter(c => c.id !== action.payload)
         }

      case types.EMPTY_CART:
         return {
            ...state,
            cart: []
         }

      case types.SET_LOADING_COUPON:
         return {
            ...state,
            loadingCoupon: action.payload
         }

      case types.SET_DISCOUNT:
         return {
            ...state,
            coupon: action.payload.coupon,
            discount: action.payload.discount,
            discountPercent: action.payload.discountPercent,
            valid: action.payload.valid
         }

      case types.CLEAN_COUPON:
         return {
            ...state,
            coupon: null,
            discount: null,
            discountPercent: null,
            valid: null,
            loadingCoupon: false,
         }

      default:
         return state;
   }
}