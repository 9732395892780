export const types = {
   SET_FAQS: '[FAQ] set faq list',
   SET_LOADING: '[FAQ] set loading state'
}



const initialState = {
   faqsList: [],
   loading: true
}



export const faqsReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_FAQS:
         return {
            ...state,
            faqsList: action.payload
         }

      case types.SET_LOADING:
         return {
            ...state,
            loading: action.payload
         }

      default:
         return state;
   }
}