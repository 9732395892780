import React from 'react';
import PropTypes from 'prop-types';




const LoadingElement = ({className, isTiny}) => {

   return (
      <section className={`element-spinner-container ${className}`}>
         <div className={`page-spinner ${isTiny && 'tiny'}`}></div>
      </section>
   );
}



LoadingElement.propTypes = {
   className: PropTypes.string,
   isTiny: PropTypes.bool
}

LoadingElement.defaultProps = {
   className: '',
   isTiny: false
}



export default LoadingElement;