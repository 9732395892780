import { configureStore } from '@reduxjs/toolkit';

// Importaciones de los reducers
import {
   addonsReducer,
   authReducer,
   bannersReducer,
   brandsReducer,
   cartReducer,
   checkoutReducer,
   eventReducer,
   faqsReducer,
   filtersReducer,
   generatedReducer,
   mainReducer,
   miscsReducer,
   offersReducer,
   officesReducer,
   paymentsReducer,
   productReducer,
   profileReducer,
   searchReducer,
   tacsReducer,
   trackingReducer,
   uiReducer,
   warrantiesReducer,
} from '../reducers';



// Main reducer
const reducers = {
   // Lista de reducers
   addons: addonsReducer,
   auth: authReducer,
   banners: bannersReducer,
   brands: brandsReducer,
   cart: cartReducer,
   checkout: checkoutReducer,
   event: eventReducer,
   faqs: faqsReducer,
   filters: filtersReducer,
   generated: generatedReducer,
   main: mainReducer,
   miscs: miscsReducer,
   offers: offersReducer,
   offices: officesReducer,
   payments: paymentsReducer,
   product: productReducer,
   profile: profileReducer,
   search: searchReducer,
   tacs: tacsReducer,
   tracking: trackingReducer,
   ui: uiReducer,
   warranties: warrantiesReducer,
}

export const store = configureStore({
   reducer: reducers,
   devTools: process.env.NODE_ENV !== 'production'
});