import { stringCapitalize } from '../helpers/format';

export const types = {
   // Actualizar el título
   SET_TITLE: '[WARRANTY] set section title',

   // Manejador de estado de carga
   SET_LOADING: '[WARRANTY] set loading state',

   // Manejador de errores
   SET_ERROR: '[WARRANTY] set error',
   CLEAN_ERRORS: '[WARRANTY] clean errors',

   // Manejador de pedido
   SET_ORDER: '[WARRANTY] set order',

   // Manejador de posiciones
   SET_POSITIONS: '[WARRANTY] set tire positions',

   // Manejador de creación
   CREATE_WARRANTY: '[WARRANTY] create warranty',

   // Manejador de garantía
   SET_WARRANTY: '[WARRANTY] set warranty data',
}



const initialState = {
   title: '',

   errors: {},

   order: null,
   loadingOrder: false,

   positions: [],
   loadingPositions: false,

   created: null,
   loadingCreate: false,

   warranty: null,
   loadingWarranty: false,
}



export const warrantiesReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_TITLE:
         return {
            ...state,
            title: action.payload
         }

      case types.SET_LOADING: {
         const { key, value } = action.payload;

         const keyValue = key.length > 0 ? stringCapitalize(key) : key;

         return {
            ...state,
            [`loading${keyValue}`]: value
         }
      }

      case types.SET_ERROR: {
         const { target, value } = action.payload

         return {
            ...state,
            errors: {
               ...state.errors,
               [target]: value
            }
         }
      }

      case types.CLEAN_ERRORS:
         return {
            ...state,
            errors: {}
         }

      case types.SET_ORDER:
         return {
            ...state,
            order: action.payload
         }

      case types.SET_POSITIONS:
         return {
            ...state,
            positions: action.payload
         }

      case types.CREATE_WARRANTY:
         return {
            ...state,
            created: action.payload
         }

      case types.SET_WARRANTY:
         return {
            ...state,
            warranty: action.payload
         }

      default:
         return state;
   }
}