import { request } from "../../helpers"

export const getOrderDetail = async (order) => {
  try {
    if (!order) throw new Error('Falta el parámetro de búsqueda')

    const response = await request({
      path: `/pedido/mostrar/${order}`,
      method: 'GET',
      // Nota: se debe enviar como asJson: false porque sino tira error
      asJson: false,
    })
      .then(({ data }) => {
        return data.datos.pedidos
      })
      .catch(err => {
        throw err
      })

    return response
  } catch (err) {
    // console.error(err);
    return err
  }
}