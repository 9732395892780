import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';



// Hook para hacer scroll to top cuando la ruta cambie
export const ScrollToTop = () => {

   const { pathname } = useLocation();

   const [prevPath, setPrevPath] = useState('/');

   useEffect(() => {
      const split = pathname.split('/');

      const splitPrev = prevPath.split('/');
      
      // Evitar funcionalidad en la vista de producto
      if (split.includes('producto') && splitPrev.includes('producto')) {
         // Solo evitar la funcionalidad cuando se hace navegación interna del producto
         if (split[2] === splitPrev[2]) return;
         // Cuando se cambia de producto ejecuta ScrollToTop
      }
      
      // Evitar funcionalidad en la vista de tracking
      if (split.includes('seguimiento-de-orden') && splitPrev.includes('seguimiento-de-orden')) return;

      // Evitar funcionalidad en la vista de información del pedido
      if (split.length > 3 && splitPrev.length > 3) return;

      window.scrollTo(0, 0);
   }, [pathname, prevPath]);

   // Funcionalidad para almacenar la ubicación anterior
   useEffect(() => {
      setPrevPath(pathname);
   }, [pathname]);

   return null;
}