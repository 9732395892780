import moment from "moment";
import { useEffect, useState } from "react";
import { getOrderDetail } from "../../services/SelfManagement/getOrderDetail.service";
import { displayProductCardName } from "../../helpers/products";
import { priceFormat } from "../../helpers";

const DetailedNV = ({ data }) => {
  const [details, setDetails] = useState(null)

  useEffect(() => {
    getOrderDetail(data?.nv?.EXT_NUM)
      .then((res) => {
        setDetails(res)
      })
  }, [data])


  return (
    <div className="self-management-detailed-nv-container">
      {data ?
        <>
          <h3 className="self-management-detailed-nv-title">Nota de venta detallada #{data?.nv?.ORDER_NUM ?? 'Error'}</h3>

          <div className="self-management-table-info">

            <div className="self-management-table-data data">
              <span>Pedido: <b>{data?.nv?.EXT_NUM ?? 'N/A'}</b></span>
              <span>Nota de venta: <b>{data?.nv?.ORDER_NUM ?? 'Error'}</b></span>
              <span>Centro de servicio: <b>{`${data?.nv?.SHIP_NAME_TEXT ?? 'Error'}, ${data?.nv?.SHIP_ADDR_TEXT ?? 'Desconocido'}`}</b></span>
            </div>

            <div className="self-management-table-agenda agenda">
              {data?.agendamiento
                ? <div className="self-management-detailed-nv-agenda-data">
                  <h4>Agenda</h4>
                  <span>{moment(data?.agendamiento?.fecha).format('DD/MM/YYYY')}</span>
                  <span>{data?.agendamiento?.hora_agenda?.hora_inicio_intervalo ?? 'Error'}</span>
                </div>

                : <b className="agenda-title none">Sin agenda</b>
              }
            </div>

            <div className="self-management-table-details details">
              {details &&
                <div className="self-management-table-detailed">
                  <table>
                    <thead>
                      <tr>
                        <th>Artículo</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>

                    <tbody>
                      {details?.neu_linea_pedidos?.length &&
                        details.neu_linea_pedidos.map(p => (
                          <tr key={`linea-${p.id}`}>
                            <td>{displayProductCardName(p.neu_producto.nombre)}</td>
                            <td>{p.cantidad_comprada}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <div className="self-management-table-detailed footer">
                    <span>Estatus: <b>{details?.neu_estatus_pedido?.nombre ?? 'Error'}</b></span>
                    <span>Total productos: <b>{details?.total_productos ?? 'Error'}</b></span>
                    <span>Total: <b>{priceFormat(details?.monto_total_pedido) ?? 'Error'}</b></span>
                  </div>
                </div>
              }
            </div>
          </div>
        </>

        : <h3 style={{ textAlign: 'center', fontStyle: 'italic' }}>No se han encontrado resultados para su búsqueda</h3>}
    </div>
  )
}

export default DetailedNV