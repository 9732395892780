import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";



// Actions
import { startGetGeolocation } from "../actions/ui";



export const useGeoLocation = () => {

   const dispatch = useDispatch();

   const { location } = useSelector(state => state.ui);

   const { lat, lng } = useGetLocation();

   useEffect(() => {
      if (!lat || !lng) return;
      
      dispatch(startGetGeolocation(lat, lng));
   }, [lat, lng, dispatch]);

   return location;
}

const useGetLocation = () => {

   const [latitude, setLatitude] = useState(null);
   const [longitude, setLongitude] = useState(null);

   useEffect(() => {
      navigator.geolocation.getCurrentPosition(position => {
         setLatitude(position.coords.latitude);
         setLongitude(position.coords.longitude);
      });
   }, []);

   return {
      lat: latitude,
      lng: longitude
   }
}