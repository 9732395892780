export const types = {
   SET_PRODUCT_DATA: '[ADDONS] set product id',
   SET_PLACES: '[ADDONS] set places list',
   SET_LOADING: '[ADDONS] set loading state',
   // Modal open state
   SET_MODAL: '[ADDONS] set modal state'
}



const initialState = {
   productId: null,
   productName: '',
   places: [],
   loading: true,

   modal: false,
}



export const addonsReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_PLACES:
         return {
            ...state,
            places: action.payload
         }

      case types.SET_PRODUCT_DATA:
         return {
            ...state,
            productId: action.payload.id,
            productName: action.payload.name
         }

      case types.SET_LOADING:
         return {
            ...state,
            loading: action.payload
         }

      case types.SET_MODAL:
         return {
            ...state,
            modal: action.payload
         }

      default:
         return state;
   }
}